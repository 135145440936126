import axios from "axios";
import { store } from "../redux/store";
// const baseUrl: string = "http://35.177.56.74:3021/api/";
// const baseUrl: string = "https://zebra-api.appworkdemo.com/api";
// const baseUrl: string = "http://13.43.194.239:3021/api";
// const baseUrl: string = "https://admin.zeebra-app.com/api";
const baseUrl: string= "https://zeebra.appworkdemo.com/api"
// const baseUrl: string = "http://localhost:8888/api/";

const dataService = axios.create({
  baseURL: baseUrl,
});

dataService.interceptors.request.use(
  (config) => {
    const token = store?.getState()?.businessReducer?.token
    if (token) {
      config.headers.auth = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default dataService;
